import React from 'react';
import './scss/styles.scss'
import Main from './components/main';

function App() {
	return (
		<Main path="/" />
	)
}

export default App;