import React from 'react';
import classNames from 'classnames';

export default function Section(props) {
  const {
    projectID,
    bgImage,
    bgColor,
    sectionTextLight,
    roleDesign,
    roleFrontend,
    platformMacOS,
    platformIOS,
    platformWindows,
    platformAndroid,
    platformWeb,
    platformWebMobile,
    title,
    description,
    sectionImage,
    showProject,
    setActiveProject,
  } = props;

  const toggleProject = (ID) => {
    document.body.classList.add('noscroll');
    showProject(true);
    setActiveProject(ID);
  }

  return (
    <section
      className={classNames('project-section', projectID && projectID)}
      style={bgColor && {backgroundColor: bgColor}}
    >
      <div className="section-info-wrap">
        <div className={classNames('section-details', sectionTextLight && 'light')}>
          <h3>{title}</h3>
          <p>{description}</p>
          
          {/* <p className="small roles-label">My Roles:</p> */}

          {(roleDesign || roleFrontend) &&
            <div className="roles-platforms">
              {roleDesign && <div><i className="icon-design"></i> Design</div> }
              {roleFrontend && <div><i className="icon-code"></i> Front-end</div> }
            </div>
          }
        
          {(platformMacOS || 
          platformIOS || 
          platformWindows || 
          platformAndroid || 
          platformWeb || 
          platformWebMobile) &&
            <div className="roles-platforms">
              {platformMacOS && <div><i className="icon-apple"></i> MacOS</div> }
              {platformIOS && <div><i className="icon-apple"></i> iOS</div> }
              {platformWindows && <div><i className="icon-microsoft"></i> Windows</div> }
              {platformAndroid && <div><i className="icon-android"></i> Android</div> }
              {platformWeb && <div><i className="icon-web"></i> Web - Desktop</div> }
              {platformWebMobile && <div><i className="icon-web"></i> Web - Desktop / Mobile</div> }
            </div>
          }

          <button className="see-more-btn" onClick={() => toggleProject(projectID)}>View Project</button>
        </div>

        {sectionImage &&
          <div className="section-image">
            <img 
              className="image"
              src={require('../images/' + sectionImage)}
              alt={sectionImage}
            />
          </div>
        }
      </div>
        
      {bgImage &&                     
        <div 
          className="section-image-fill" 
          style={{backgroundImage: `url(${require('../images/' + bgImage)})`}}
        />
      }
    </section>
  )
}