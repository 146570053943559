import React from 'react';
import classNames from 'classnames';

export default function Other(props) {
  const {
    projectID,
    bgImage,
    bgColor,
    title,
    description
  } = props;

  return (
    <div
      className={classNames('other-project ' + projectID)}
      style={Object.assign({},
        bgImage && {backgroundImage: `url(${require('../images/' + bgImage)})`},
        bgColor && {backgroundColor: `${bgColor}`}
      )}
    >
      <div className="project-details">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  )
}