import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';

import Logo from '../images/misc/logo-new.svg';
import bannerBg from '../images/misc/banner-bg-dark.png';
import Portfolio from '../portfolio.json';

import ProjectSection from '../components/project-section';
import ProjectOther from '../components/project-other';
import ProjectPage from '../components/project-page';

export default function Main() {
  const [projectVisible, setProjectVisible] = useState(false);
  const [activeProject, setActiveProject] = useState("");
  const [affixHeader, setAffixHeader] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const contentWrapperRef = useRef();

  const about = Portfolio;
  const recentProjects = Portfolio.RecentProjects;
  const brandliveProjects = Portfolio.BrandliveProjects;
  const otherProjects = Portfolio.OtherProjects;

  useEffect(() => {
    const scrollListener = () => {
      let scrollPos = window.scrollY;
      // let contentWrapperPos = contentWrapperRef.current.getBoundingClientRect();

      // console.log(contentWrapperPos.top);

      if (scrollPos > 600) {
        setAffixHeader(true);
      } else {
        setAffixHeader(false);
      }
    };

    window.addEventListener("scroll", scrollListener);

    return () => window.removeEventListener("scroll", scrollListener);
  }, [contentWrapperRef]);

  const handleWorkScroll = () => {
    contentWrapperRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  return (
    <>
      <header className={affixHeader ? 'fixed' : null}>
        <a href="/" className="logo-wrap">
          <img className="logo" src={Logo} alt={Logo} />
        </a>

        <div className="name">Hi, I'm Spenser</div>

        <nav className={menuOpen ? 'active' : null}>
          <a onClick={() => setMenuOpen(false)} href="#work">Work</a>
          <a onClick={() => setMenuOpen(false)} href="#about">About</a>
          <a onClick={() => setMenuOpen(false)} href="mailto:spenser.lea@gmail.com" target="_blank" rel="noopener noreferrer">Contact</a>
        </nav>

        <div className={menuOpen ? 'mobile-menu-btn active' : 'mobile-menu-btn'} onClick={() => setMenuOpen(!menuOpen)}>
          <span></span><span></span><span></span>
        </div>
      </header>

      <main className="main-wrapper">
        <section className="banner-wrapper" style={{ backgroundImage: `url(${bannerBg})` }}>
          <div className="banner-info">            
            <h1>
              {about.sloganLine1 && about.sloganLine1}
              {about.sloganLine2 &&
                <>
                  <br /> {about.sloganLine2}
                </>
              }
            </h1>
            
            <p>{about.title && about.title}</p>
            
            <button className="button large" onClick={() => handleWorkScroll()}>See My Work</button>
          </div>

          {/* <a href="#work" className="work-action">See my work <i className="icon-chevron-down"></i></a> */}

          <div className="hero-dots">
            {Array(75).fill().map((_, i) => {
              return (
                <div
                  className={classNames(
                    'dot-wrapper',
                    'dot-wrapper-' + i
                  )}
                  key={i} 
                >
                  <div
                    className={classNames(
                      'dot',
                      'dot-' + i
                    )}
                    key={i} 
                  ></div>
                </div>
              )
            })}
          </div>
        </section>

        <div className="content-wrapper" ref={contentWrapperRef}>
          <section id="work" className="products project-section-wrapper">
            <div className="title">
              <h2>Recent Work</h2>
            </div>

            {recentProjects.map((project, i) => {
              return (
                <ProjectSection
                  key={i}
                  i={i}
                  projectID={project.projectID}
                  bgImage={project.bgImage}
                  bgColor={project.bgColor}
                  sectionTextLight={project.sectionTextLight}
                  roleDesign={project.roleDesign}
                  roleFrontend={project.roleFrontend}
                  platformMacOS={project.platformMacOS}
                  platformIOS={project.platformIOS}
                  platformWindows={project.platformWindows}
                  platformAndroid={project.platformAndroid}
                  platformWeb={project.platformWeb}
                  platformWebMobile={project.platformWebMobile}
                  title={project.title}
                  description={project.description}
                  sectionImage={project.sectionImage}
                  showProject={setProjectVisible}
                  setActiveProject={setActiveProject}
                />
              )
            })}

            <div className="title">
              <h2>Brandlive Work</h2>
            </div>

            {brandliveProjects.map((project, i) => {
              return (
                <ProjectSection
                  key={i}
                  i={i}
                  projectID={project.projectID}
                  bgImage={project.bgImage}
                  bgColor={project.bgColor}
                  sectionTextLight={project.sectionTextLight}
                  roleDesign={project.roleDesign}
                  roleFrontend={project.roleFrontend}
                  platformMacOS={project.platformMacOS}
                  platformIOS={project.platformIOS}
                  platformWindows={project.platformWindows}
                  platformAndroid={project.platformAndroid}
                  platformWeb={project.platformWeb}
                  platformWebMobile={project.platformWebMobile}
                  title={project.title}
                  description={project.description}
                  sectionImage={project.sectionImage}
                  showProject={setProjectVisible}
                  setActiveProject={setActiveProject}
                />
              )
            })}
            
            <div className="title">
              <h2>Other Projects</h2>
            </div>

            <div className="other-projects">
              {otherProjects.map((project, i) => {
                return (
                  <ProjectOther
                    key={i}
                    id={project.id}
                    projectID={project.projectID}
                    bgImage={project.bgImage}
                    sectionImage={project.sectionImage}
                    bgColor={project.bgColor}
                    title={project.title}
                    description={project.description}
                  />
                )
              })}
            </div>
          </section>

          <section className="brand-experience">
            <div className="brand-info">
              <div className="line"></div>
              <h4>{about.brandExperienceLabel}</h4>
              <img src={require('../images/' + about.brandExperienceLogos)} alt={about.brandExperienceLogos} />
              <div className="line"></div>
            </div>
          </section>

          <section id="about" className="about project-section-wrapper">
            <div className="title">
              <h2>About Me</h2>
            </div>

            <div className="about-info-wrap">
              <div className="about-top">
                <div className="about-image" style={{ backgroundImage: `url(${require('../images/' + about.profilePic)})` }}></div>

                <div className="about-info1">
                  {/* <h5>{about.aboutTitle}</h5> */}
                  <p>{about.aboutInfo1a}</p>
                </div>
              </div>

              <div className="about-bottom">
                <div className="about-info2">
                  <p>{about.aboutInfo2a}</p>
                  <p>{about.aboutInfo2b}</p>
                </div>

                <div className="contact">
                  <div><i className="icon-location"></i> Bend, Oregon</div>
                  <div><i className="icon-mail"></i> <a href="mailto:spenser.lea@gmail.com" target="_blank" rel="noopener noreferrer">spenser.lea@gmail.com</a></div>
                  {/* <div><i className="icon-dribbble"></i> <a href="https://dribbble.com/spenserlea" target="_blank" rel="noopener noreferrer">Dribbble</a></div> */}
                  <div><i className="icon-linkedin"></i> <a href="https://www.linkedin.com/in/spenserlea/" target="_blank" rel="noopener noreferrer">LinkedIn</a></div>
                  <div><i className="icon-instagram"></i> <a href="https://www.instagram.com/spenselea/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>

      {projectVisible && recentProjects.map((project, i) => {
        return activeProject === project.projectID && (
          <ProjectPage
            key={i}
            project={project}
            logo={Logo}
            setProjectVisible={setProjectVisible}
            projectVisible={projectVisible}
          />
        )
      })}

      {projectVisible && brandliveProjects.map((project, i) => {
        return activeProject === project.projectID && (
          <ProjectPage
            key={i}
            project={project}
            logo={Logo}
            setProjectVisible={setProjectVisible}
            projectVisible={projectVisible}
          />
        )
      })}
    </>
  )
}